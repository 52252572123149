import React from "react"
import styled from "styled-components"

const StyledLink = styled.a`
  color: #5dade2;
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #5dade2;
  margin-right: ${props => (props.margin ? "1.5rem" : 0)};
`
const ExternalLink = props => (
  <StyledLink
    margin={props.margin}
    href={props.url}
    target="_blank"
    rel="noreferrer"
  >
    {props.label}
  </StyledLink>
)

export default ExternalLink
