import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import forestAdventure from "../images/forestAdventure.gif"
import gameOfLife from "../images/gameOfLife.gif"
import ExternalLink from "../components/externalLink"
import GracesBakery from "../components/imageComponents/GracesBakery"
import TamaDo from "../components/imageComponents/TamaDo"
import DoggyWeather from "../components/imageComponents/DoggyWeather"

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: "center";
  align-items: "center";
  padding: 2rem 0;
  border-bottom: 3px solid white;
`

const ProjectText = styled.div`
  max-width: 400px;
  margin: 0 auto;
`

const StyledGif = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: 350px;
  max-width: 400px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
`

const StyledImg = styled.div`
  min-height: 200px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`

const PortfolioPage = () => (
  <Layout>
    <SEO title="Portfolio" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Portfolio</h1>
    <ProjectContainer>
      <StyledImg>
        <GracesBakery />
      </StyledImg>
      <ProjectText>
        <h2>Grace's Bakery</h2>
        <p>
          A fictional e-commerce site for delivering freshly baked goods. Built
          with React, Redux, Express, Sequelize and PostgreSQL.
        </p>
        <ExternalLink
          margin={true}
          url="https://github.com/stella-yc/graces-bakery"
          label="Code"
        />
        <ExternalLink
          margin={true}
          url="https://gracesbakery.herokuapp.com/"
          label="Demo"
        />
      </ProjectText>
    </ProjectContainer>
    <ProjectContainer>
      <StyledGif src={forestAdventure} />
      <ProjectText>
        <h2>Forest Adventure</h2>
        <p>
          A classic 2D platformer built with Phaser.js, ES6 and webpack. Shoot
          stars, plant & climb trees, and collect all 5 gems! Created as part of
          an independent Stackathon project at Fullstack Academy.
        </p>
        <ExternalLink
          margin={true}
          url="https://github.com/stella-yc/forest-adventure"
          label="Code"
        />
        <ExternalLink
          margin={true}
          url="https://www.youtube.com/watch?v=XOOIo-aKyUA"
          label="Presentation"
        />
      </ProjectText>
    </ProjectContainer>
    <ProjectContainer>
      <StyledImg>
        <TamaDo />
      </StyledImg>
      <ProjectText>
        <h2>Tama-Do</h2>
        <p>
          A gamified productivity app with virtual pets placed at locations of
          the user's choosing in the real world. Built by a team of four
          developers, using React Native and Firebase. Winner of Fullstack
          Academy's Developer's Choice Award.
        </p>
        <ExternalLink
          margin={true}
          url="https://github.com/Tama-Do/tama-do"
          label="Code"
        />
        <ExternalLink
          margin={true}
          url="https://www.youtube.com/watch?v=fH4YrnoB3-M&list=PLx0iOsdUOUmnCoQp2wnimmDBx22j2vXBU&index=3"
          label="Presentation"
        />
      </ProjectText>
    </ProjectContainer>
    <ProjectContainer>
      <StyledImg>
        <DoggyWeather />
      </StyledImg>
      <ProjectText>
        <h2>Weather App</h2>
        <p>
          A web app that displays local weather information and different
          pictures of dogs depending on the forecast. Built using jQuery as part
          of a freeCodeCamp project.
        </p>
        <ExternalLink
          margin={true}
          url="https://github.com/stella-yc/weather-app"
          label="Code"
        />
        <ExternalLink
          margin={true}
          url="https://stella-yc.github.io/weather-app/"
          label="Demo"
        />
      </ProjectText>
    </ProjectContainer>
    <ProjectContainer>
      <StyledGif src={gameOfLife} />
      <ProjectText>
        <h2>Conway's Game of Life</h2>
        <p>
          A web app that allows users to set up the initial state of the game by
          clicking on tiles, or generate a random start state. Built using pure
          JavaScript
        </p>
        <ExternalLink
          margin={true}
          url="https://github.com/stella-yc/conways-game-of-life"
          label="Code"
        />
        <ExternalLink
          margin={true}
          url="https://stella-yc.github.io/conways-game-of-life/"
          label="Demo"
        />
      </ProjectText>
    </ProjectContainer>
  </Layout>
)

export default PortfolioPage
